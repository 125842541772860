import React from 'react'

//TODO: could be generic if provided with company name

const Footer = () => (
  <div id="footer">
    &copy; Ace High Plumbing Ltd. All Rights Reserved. <br/>
    Website designed and developed by <a href="http://nahkies.co.nz/" target="_blank">Michael Nahkies</a>
  </div>
)

export default Footer
