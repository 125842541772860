import React from 'react'

import headerImage from '../img/headerImage.jpg'
import logoVectorBw from '../img/logo_vector_bw-120x100.jpg'
import Menu from './menu'

const Header = () => (
  <div id="header">
    <h1 style={{ float: 'left', fontSize: '21px' }}>Ace High Plumbing</h1>
    <img src={headerImage} width="620" height="120" style={{ float: 'right' }}/>

    <div id="logo">
      <img src={logoVectorBw} width="120" height="100" alt="Logo"/>
    </div>

    <div id="headerInner">
      <p style={{ float: 'left', marginTop: '20px', marginBottom: '20px' }}>021 454 864 <br/>Home of the Royal Flush
      </p>
      <Menu/>
    </div>

    <br className="clear"/>
  </div>
)

export default Header
