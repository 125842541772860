export const routes = [
  { title: 'Home', href: '/' },
  {
    title: 'Services',
    href: '/services',
    children: [
      {
        title: 'Plumbing',
        href: '/services/plumbing',

      },
      {
        title: 'Drainlaying',
        href: '/services/drainlaying',

      },
      {
        title: 'Gasfitting',
        href: '/services/gasfitting',

      },
    ],
  },
  { title: 'Gallery', href: '/gallery' },
  { title: 'Quote', href: '/quote' },
  { title: 'Contact Us', href: '/contact' },
]
