import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withPrefix } from 'gatsby-link'

import '../css/normalize.css'
import '../css/style.css'

import '../lib/jquery-ui-1.10.3/themes/base/minified/jquery-ui.min.css'

import Header from '../components/header'
import Footer from '../components/footer'
import { graphql, StaticQuery } from 'gatsby'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
    }

    render={data => (
      <div>

        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <link href='http://fonts.googleapis.com/css?family=Michroma' rel='stylesheet' type='text/css'/>
          <link
            href="//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.4/jquery.fancybox.min.css" media="screen"
            rel="stylesheet"
            type="text/css"
          />
          <link href={withPrefix('/human.css')} rel='stylesheet' type='text/css'/>
        </Helmet>

        <div id="container">
          <Header/>
          {children}
        </div>

        <Footer/>
      </div>
    )}/>
)


Layout.propTypes = {
  children: PropTypes.func,
}


export default Layout
