import React from 'react'
import Link from 'gatsby-link'

import menuDivider from '../img/menuDivider-20x20.png'
import { routes } from '../routes'

class MenuItem extends React.Component {
  render() {
    const classes = []

    if (this.props.first) {
      classes.push('first')
    }

    if (this.props.last) {
      classes.push('last')
    }

    const href = this.props.href
    const title = this.props.title
    const children = this.props.children || []

    return (
      <li className={classes.join(' ')}>
        <Link to={href} title={title}>
          <img src={menuDivider} height="20" width="20"/> {title}
        </Link>
        {this.generateSubMenu(children)}
      </li>
    )
  }

  generateSubMenu(children) {
    if (children.length == 0) {
      return ('')
    }

    return (
      <ul>
        {children.map(item => {
          return (<MenuItem title={item.title} href={item.href} children={item.children}/>)
        })}
      </ul>
    )
  }
}

class Menu extends React.Component {
  render() {
    return (
      <ul className="menu">
        {routes.map(item => {
          return (<MenuItem title={item.title} href={item.href} children={item.children}/>)
        })}
      </ul>
    )
  }
}

export default Menu
